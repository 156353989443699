// NOTES:
// - You can include up to 50 blocks in each message, and 100 blocks in modals or home tabs.

import { SlackSurvey } from 'src/models';

export const TEXT_TYPES = {
  PLAIN_TEXT: 'plain_text',
  MARKDOWN: 'mrkdwn',
};

export const ELEMENT_TYPES = {
  IMAGE: 'image',
};

export const BLOCK_TYPES = {
  SECTION: 'section',
  IMAGE: 'image',
  DIVIDER: 'divider',
  HEADER: 'header',
  CONTEXT: 'context',
  BUTTONS: 'actions',
  SURVEY: 'survey',
};

export const BLOCK_NAMES = {
  [BLOCK_TYPES.SECTION]: 'Section',
  [BLOCK_TYPES.IMAGE]: 'Image',
  [BLOCK_TYPES.DIVIDER]: 'Divider',
  [BLOCK_TYPES.HEADER]: 'Header',
  [BLOCK_TYPES.CONTEXT]: 'Context',
  [BLOCK_TYPES.BUTTONS]: 'Buttons',
  [BLOCK_TYPES.SURVEY]: 'Survey',
};

export type BlockType =
  | typeof BLOCK_TYPES.SECTION
  | typeof BLOCK_TYPES.IMAGE
  | typeof BLOCK_TYPES.DIVIDER
  | typeof BLOCK_TYPES.HEADER
  | typeof BLOCK_TYPES.CONTEXT
  | typeof BLOCK_TYPES.BUTTONS
  | typeof BLOCK_TYPES.SURVEY;

interface PlainTextObject {
  type: typeof TEXT_TYPES.PLAIN_TEXT;
  text: string;
  emoji?: boolean; // Escapes emojis to colon format.
}

interface MarkdownObject {
  type: typeof TEXT_TYPES.MARKDOWN;
  text: string;
  verbatim?: boolean; // Auto converts links, names, etc if false. Defaults to false.
}

type TextObject = PlainTextObject | MarkdownObject;

interface ImageElement {
  type: 'image';
  image_url: string;
  alt_text: string;
}

interface ButtonElement {
  action_id?: string;
  type: 'button';
  text: {
    type: string;
    text: string;
    emoji: boolean;
  };
  style?: string;
  value: string;
  url: string;
}

type Element = ImageElement;

export interface SectionBlock {
  type: typeof BLOCK_TYPES.SECTION;
  text?: TextObject;
  accessory?: Element;
  block_id?: string;
  fields?: TextObject[]; // Required if no text is provided. An array of text objects. Any text objects included with fields will be rendered in a compact format that allows for 2 columns of side-by-side text. Maximum number of items is 10. Maximum length for the text in each item is 2000 characters.
}

interface ImageBlock {
  type: typeof BLOCK_TYPES.IMAGE;
  image_url: string; // Maximum length for this field is 3000 characters.
  alt_text: string; // Maximum length for this field is 2000 characters
  title?: PlainTextObject; // Maximum length for the text in this field is 2000 characters.
  block_id?: string;
}

interface DividerBlock {
  type: typeof BLOCK_TYPES.DIVIDER;
  block_id?: string;
}

export interface HeaderBlock {
  type: typeof BLOCK_TYPES.HEADER;
  text: PlainTextObject; // Maximum length for the text in this field is 150 characters.
  block_id?: string;
}

interface ContextBlock {
  type: typeof BLOCK_TYPES.CONTEXT;
  elements: (TextObject | ImageElement)[]; // Maximum number of items is 10.
  block_id?: string;
}

export interface ButtonsBlock {
  type: typeof BLOCK_TYPES.BUTTONS;
  elements: ButtonElement[];
  block_id?: string;
}

export interface SurveyBlock {
  type: typeof BLOCK_TYPES.BUTTONS;
  survey: SlackSurvey;
  block_id?: string;
}

export type Block = (
  | SectionBlock
  | ImageBlock
  | DividerBlock
  | HeaderBlock
  | ContextBlock
  | ButtonsBlock
  | SurveyBlock
) & { survey_id?: string };

export function isSection(block: Block): block is SectionBlock {
  return block.type === BLOCK_TYPES.SECTION;
}

export function isHeader(block: Block): block is HeaderBlock {
  return block.type === BLOCK_TYPES.HEADER;
}

export function isContext(block: Block): block is ContextBlock {
  return block.type === BLOCK_TYPES.CONTEXT;
}

export function isButtons(block: Block): block is ButtonsBlock {
  return block.type === BLOCK_TYPES.BUTTONS;
}

export function isMrkdwn(text: TextObject): text is MarkdownObject {
  return text.type === TEXT_TYPES.MARKDOWN;
}

export function isText(text: ImageElement | TextObject): text is TextObject {
  return text.type === TEXT_TYPES.MARKDOWN || text.type === TEXT_TYPES.PLAIN_TEXT;
}

export function isImage(image: ImageElement | TextObject): image is ImageElement {
  return image.type === ELEMENT_TYPES.IMAGE;
}

export function isSurvey(block: Block): block is SurveyBlock {
  return block.type === BLOCK_TYPES.SURVEY;
}

export interface SendAs {
  type: string;
  id?: string;
}
