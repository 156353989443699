import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AnalyticsService } from 'src/services/analytics.service';
import { ApiService } from 'src/services/api.service';

@Component({
  selector: 'app-giphy-modal',
  templateUrl: './giphy-modal.component.html',
  styleUrls: ['./giphy-modal.component.scss'],
})
export class GiphyModalComponent implements OnInit {
  searchValue: string;
  gifs: any[];

  constructor(
    private apiService: ApiService,
    private modalCtrl: ModalController,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    this.getGifs();
  }

  async getGifs() {
    const request = this.apiService.get(`/giphy`, {
      search: this.searchValue || '',
    });

    request.subscribe((result: any) => {
      if (result) {
        this.gifs = result.data;
      }
    });
  }

  selectGif(gif: string) {
    this.analyticsService.trackEvent('Giphy Modal', 'Select gif');

    this.modalCtrl.dismiss({
      url: gif,
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
